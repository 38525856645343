import React, { useEffect, useState } from "react";
import apiCompany from "../../../apis/company/apiCompany";
import AppAlert from "../../common/AppAlert";
import AppLoading from "../../common/AppLoading";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";
import apiWorkUnit from "../../../apis/workUnit/apiWorkUnit";

function WorkUnitCreateScreen() {
	const [name, setName] = useState("");
	const [company, setCompany] = useState({});
	const [loading, setLoading] = useState(false);
	const [companyList, setCompanyList] = useState([]);
	const [errorsForm, setErrorsForm] = useState({});

	const CreateWorkUnit = async () => {
		const workUnit = {
			name: name,
			company_id: company.value,
		};

		setLoading(true);
		const response = await apiWorkUnit.createWorkUnit(workUnit);
		setLoading(false);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			setErrorsForm({});
		} else {
			setErrorsForm(response);
		}
	};

	const GetCompanies = async () => {
		const response = await apiCompany.getCompaniesDropdown();

		if (response.ok) {
			setCompanyList(response.data);
		}
	};

	useEffect(() => {
		GetCompanies();
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Registro de Unidad de Trabajo" />
				<BreadCrumb items={[{ label: "Unidades de Trabajo" }, { label: "Registro de Unidad de Trabajo", active: true }]} />
			</div>
			<div className="card col-12">
				<div className="row card-body col-12">
					<div className="col-6">
						<AppInput
							label="Nombre del Unidad de Trabajo"
							type="text"
							onChange={({ target }) => setName(target.value)}
							placeholder="Nombre del Unidad de Trabajo"
							errors={errorsForm.name}
						/>
					</div>
					<div className="col-6">
						<AppSelect label="Compañía" items={companyList} onChange={(dinner) => setCompany(dinner ? dinner : {})} errors={errorsForm.company_id} />
					</div>
					<div className="col-12 text-center my-4">
						<button className="btn btn-primary" onClick={CreateWorkUnit}>
							Registrar Unidad de Trabajo
						</button>
					</div>
				</div>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default WorkUnitCreateScreen;
