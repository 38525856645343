import React from "react";
import { Link } from "react-router-dom";

function AppBreadCrumb({ items = [] }) {
	return (
		<div className="py-1 mb-3">
			{items.map((item, index) => {
				return (
					<Link key={index} className="text-capitalize text-decoration-none" to={item.to ? item.to : "/"}>
						<small className={item.active ? "text-primary" : "text-muted"}>
							{index == 0 ? "" : " / "} {item.label}
						</small>
					</Link>
				);
			})}
		</div>
	);
}

export default AppBreadCrumb;
