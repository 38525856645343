import React from "react";
import BreadCrumb from "../../common/AppBreadCrumb";
import PersonCreateForm from "../../components/PersonCreateForm";
import AppTitle from "../../controls/AppTitle";
function PersonCreateScreen() {
	return (
		<div className="row">
			<div className="col-md-12">
				<AppTitle text="Registro de Persona: " />
				<BreadCrumb items={[{ label: "Personas" }, { label: "Registro de Personas", active: true }]} />
			</div>
			<div className="col-md-12">
				<PersonCreateForm />
			</div>
		</div>
	);
}

export default PersonCreateScreen;
