import React, { useRef, useState } from "react";
import apiPerson from "../../../apis/person/apiPerson";
import AppInput from "../../controls/AppInput";
import WithdrawalActToPrint from "./WithdrawalActToPrint";
import { useReactToPrint } from "react-to-print";
import AppTitle from "../../controls/AppTitle";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import AppCalendar from "../../controls/AppCalendar";

function MeterWithdrawalAct() {
	const componentRef = useRef();

	const [inscriptionCode, setInscriptionCode] = useState("");
	const [person, setPerson] = useState({});
	const [measurerStates, setMeasurerStates] = useState("");
	const [date, setDate] = useState(new Date());

	const SearchPerson = async () => {
		var response = await apiPerson.getPersonByInscriptionCode(inscriptionCode);

		if (response.ok) {
			var person = response.data;
			var states = "";

			for (let index = 0; index < response.data.measurer.meter_states.length; index++) {
				states += response.data.measurer.meter_states[index].name + ", ";
			}

			person.states = states;

			setPerson(person);

			setMeasurerStates(states);
			SendToPrintForm();
		}
	};

	const onChangeInscriptionCode = (event) => {
		if (event.key === "Enter") {
			SearchPerson();
		}
	};

	const SendToPrintForm = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<div className="row p-5">
			<div className="col-10">
				<AppTitle text="Acta de Retiro de medidor: " />
				<AppBreadCrumb items={[{ label: "Formularios" }, { label: "Notificaciones de Retiro", active: true }]} />
			</div>

			<div className="col-md-12 card">
				<div className="card-body text-center col-md-12">
					<div className="col-md-12 py-5">
						<AppCalendar label="Fecha de Asignacion:" value={date} onChange={setDate} />
					</div>
					<AppInput
						label="Codigo de Inscripcion del Usuario"
						onKeyPress={(event) => onChangeInscriptionCode(event)}
						onChange={({ target }) => setInscriptionCode(target.value)}
						placeholder="Codigo de Inscripcion"
					/>
					<button className="btn btn-primary" onClick={SendToPrintForm}>
						Enviar a Imprimir
					</button>
				</div>
			</div>
			<div className="col-md-12">
				<WithdrawalActToPrint ref={componentRef} person={person} date={date.toLocaleDateString()} />
			</div>
		</div>
	);
}

export default MeterWithdrawalAct;
