import React, { Component } from "react";

export default class NotificationActToPrint extends Component {
	render() {
		const person = this.props.person;
		const notificationDate = this.props.notificationDate;
		const instalationDate = this.props.instalationDate;

		return (
			<div className="table w-100" style={{ fontSize: 14, fontWeight: 600, letterSpacing: 4, fontFamily: "unset", top: 0, position: "absolute" }}>
				<div className="table w-100" style={{ fontSize: 12, fontWeight: 600, letterSpacing: 4, fontFamily: "unset", top: 100, position: "absolute" }}>
					<span style={{ position: "absolute", left: 500, top: 40 }}>CONSULTAS:</span>
					<span style={{ position: "absolute", left: 650, top: 15 }}>-977 994 875</span>
					<span style={{ position: "absolute", left: 650, top: 30 }}>-920 114 410</span>
					<span style={{ position: "absolute", left: 650, top: 45 }}>-988 113 972</span>
					<span style={{ position: "absolute", left: 650, top: 60 }}>-938 162 233</span>
				</div>
				<div className="table w-100" style={{ fontSize: 14, fontWeight: 600, letterSpacing: 4, fontFamily: "unset", top: 215, position: "absolute" }}>
					<span style={{ position: "absolute", left: 75 }}>{person.person}</span>
					<br />
					<span style={{ position: "absolute", left: 75 }}>{person.address}</span>
					<br />
					<span style={{ position: "absolute", left: 75 }}>
						{person.contractor_code}-{person.block}-{person.lot}-{person.sub_lot}
					</span>
					<span style={{ position: "absolute", left: 550 }}>{person.inscription_code}</span>
					<br />
					<span style={{ position: "absolute", left: 75 }}>{person.measurer ? person.measurer.code : "Sin información, por verificar"}</span>
					<br />
					<span style={{ position: "absolute", left: 75, top: 90 }}>{notificationDate}</span>
					<span style={{ position: "absolute", top: 400, left: 150 }}>{person.states_names}</span>
					<br />
					<span style={{ position: "absolute", top: 375, left: 350 }}>{person.patron}</span>
					<span style={{ position: "absolute", left: 350, top: 350 }}>{instalationDate}</span>
				</div>
			</div>
		);
	}
}
