import apiBase from "../apiBase";

export default {
	createContractor(contractor) {
		const url = "api/company/contractor/create";
		return apiBase.post(url, contractor);
	},
	deleteContractor(contractor_id) {
		const url = "api/company/contractor/delete/" + contractor_id;
		return apiBase.delete(url);
	},
	updateContractor(contractor) {
		const url = "api/company/contractor/update";
		return apiBase.put(url, contractor);
	},
	getContractors() {
		const url = "api/company/contractor/getAllContractors";
		return apiBase.get(url);
	},
	getContractorsPaginated(page = 1, filters = "") {
		const url = "api/company/contractor/getContractorsPaginated?page=" + page + "&&filters" + filters;
		return apiBase.get(url);
	},
	getContractorsDropdown() {
		const url = "api/company/contractor/getContractorsDropdown";
		return apiBase.get(url);
	},
};
