import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import apiDinner from "../../../apis/dinner/apiDinner";
import appColors from "../../../config/appColors";
import AppAlert from "../../common/AppAlert";
import BreadCrumb from "../../common/AppBreadCrumb";
import TablePaginate from "../../components/TablePaginate";
import AppTitle from "../../controls/AppTitle";

function ListDinnerPointsScreen() {
	const headers = ["Fecha", "Nombre Punto Servicio", "Comedor asignado"];
	const values = ["created", "name", "dinner_name"];
	const buttons = [
		{ onClick: (dinnerPoint) => EditSelectedDinnerPoint(dinnerPoint), style: "btn", iconName: "pen", iconColor: appColors.green_04 },
		{ onClick: (dinnerPoint) => ShowDeleteSelectedDinnerPoint(dinnerPoint), style: "btn", iconName: "trash-alt", iconColor: appColors.red_04 },
	];

	const history = useHistory();
	const [filter, setFilter] = useState("");
	const [paginator, setPaginator] = useState({});
	const [dinnerPoints, setDinnerPoints] = useState([]);

	const EditSelectedDinnerPoint = (dinner) => {
		history.push({ pathname: "/dinner-point/edit", state: dinner });
	};

	const GetDinnerPoints = async (page = 1) => {
		var response = await apiDinner.getDinnerPointsPaginated(page, filter);

		if (response.ok) {
			setPaginator(response.data.paginator);
			setDinnerPoints(response.data.results);
		}
	};

	const ShowDeleteSelectedDinnerPoint = (dinnerPoint) => {
		AppAlert.OptionsAlert(
			"Este punto de servicio se eliminará, y ya no estará disponible para el resto de operaciones, y se eliminará toda su información.",
			"Eliminar de todos modos"
		).then((response) => {
			if (response) {
				DeleteDinnerPoint(dinnerPoint.id);
			}
		});
	};

	const DeleteDinnerPoint = async (dinner_point_id) => {
		var response = await apiDinner.deleteDinnerPoint(dinner_point_id);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			GetDinnerPoints();
		}
	};
	useEffect(() => {
		GetDinnerPoints();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-10">
				<AppTitle text="Lista de Puntos de Servicio: " />
				<BreadCrumb items={[{ label: "Puntos Servicio" }, { label: "Lista puntos de Servicio", active: true }]} />
			</div>
			<div className="col-2 d-flex align-items-center">
				<Link className="btn btn-sm btn-success" to={"dinner-point/create"}>
					Registrar Punto Servicio
				</Link>
			</div>
			<div className="col-12">
				<TablePaginate
					headers={headers}
					buttons={buttons}
					rows={dinnerPoints}
					values={values}
					onSearchApi={(page) => GetDinnerPoints(page)}
					onChangeFilter={setFilter}
					paginator={paginator}
				/>
			</div>
		</div>
	);
}

export default ListDinnerPointsScreen;
