import React from "react";
import AppIcon from "../controls/AppIcon";

function TablePaginate({
	rows = [],
	headers = [],
	values = [],
	placeholder = "Búsqueda por nombre ...",
	searchable = true,
	onChangeFilter,
	paginator = {},
	onSearchApi,
	buttons = [],
}) {
	const OnEnterPress = async (event) => {
		if (event.key === "Enter") {
			onSearchApi(1);
		}
	};

	return (
		<div className="table-responsive">
			{searchable && (
				<div className="mb-3">
					<div className="input-group">
						<input
							type="text"
							placeholder={placeholder}
							onKeyPress={(event) => OnEnterPress(event)}
							onChange={({ target }) => onChangeFilter(target.value)}
							className="form-control"
						/>
						<div className="input-group-append">
							<button onClick={() => onSearchApi(1)} className="btn btn-dark">
								Buscar
							</button>
						</div>
					</div>
				</div>
			)}
			<table className="table table-hover table-bordered">
				<thead className="thead-dark">
					<tr>
						{headers.map((header, index) => {
							return (
								<th className="text-center" scope="col" key={index}>
									<small>
										<b>{header}</b>
									</small>
								</th>
							);
						})}
						<th className={"text-center"} scope="col">
							<small>
								<b>Opciones</b>
							</small>
						</th>
					</tr>
				</thead>
				<tbody>
					{rows.map((item, indexItem) => {
						return (
							<tr key={indexItem}>
								{values.map((value, indexValue) => {
									return (
										<td scope="row" key={indexValue}>
											<small>{item[value]}</small>
										</td>
									);
								})}
								<td className="text-center" scope="row">
									{buttons.length != 0 ? (
										buttons.map((button, index) => {
											return (
												<button key={index} className={button.style} onClick={() => button.onClick(item)}>
													{button.iconName && <AppIcon iconName={button.iconName} color={button.iconColor} />}
													{button.text && <small>{button.text}</small>}
												</button>
											);
										})
									) : (
										<span className="small">No disponible</span>
									)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="">
				<div className="mr-auto py-2">
					<ul className="pagination justify-content-end">
						<li className="page-item">
							<a className="page-link" onClick={() => (parseInt(paginator.page) != 1 ? onSearchApi(1) : {})} aria-label="Next">
								<span className="small" aria-hidden="true">
									Inicio
								</span>
							</a>
						</li>

						{paginator.page != 1 ? (
							<li className="page-item ">
								<a className="page-link" onClick={() => onSearchApi(paginator.page - 1)}>
									<span className="small" aria-hidden="true">
										anterior
									</span>
								</a>
							</li>
						) : (
							<li className="page-item disabled">
								<a className="page-link">
									<span className="small" aria-hidden="true">
										anterior
									</span>
								</a>
							</li>
						)}
						<li className="page-item disabled">
							<a className="page-link bg-light">
								<span className="small text-dark">
									Página {paginator.page} de {paginator.last_page}, existen {paginator.count} registros.
								</span>
							</a>
						</li>
						{paginator.page != paginator.last_page ? (
							<li className="page-item">
								<a className="page-link" onClick={() => onSearchApi(paginator.page + 1)}>
									<span className="small">siguiente</span>
								</a>
							</li>
						) : (
							<li className="page-item disabled">
								<a className="page-link">
									<span className="small">siguiente</span>
								</a>
							</li>
						)}
						<li className="page-item">
							<a className="page-link" onClick={() => onSearchApi(paginator.last_page)} aria-label="Next">
								<span className="small" aria-hidden="true">
									Fin
								</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default TablePaginate;
