import React, { useEffect, useState } from "react";
import apiDinner from "../../../apis/dinner/apiDinner";
import TablePaginate from "../../components/TablePaginate";
import AppTitle from "../../controls/AppTitle";
import AppLoading from "../../common/AppLoading";
import { Link, useHistory } from "react-router-dom";
import BreadCrumb from "../../common/AppBreadCrumb";
import appColors from "../../../config/appColors";
import AppAlert from "../../common/AppAlert";
import apiProduct from "../../../apis/product/apiProduct";

function ListProductsScreen() {
	const headers = ["Fecha", "Nombre Producto", "Precio", "Comedor"];
	const values = ["created", "name", "price", "dinner_name"];
	const buttons = [
		{ onClick: (product) => EditSelectedProduct(product), style: "btn", iconName: "pen", iconColor: appColors.green_04 },
		{ onClick: (product) => ShowDeleteSelectedProduct(product), style: "btn", iconName: "trash-alt", iconColor: appColors.red_04 },
	];

	const history = useHistory();
	const [filter, setFilter] = useState("");
	const [paginator, setPaginator] = useState({});
	const [products, setDinners] = useState([]);
	const [loading, setLoading] = useState(false);

	const GetProducts = async (page = 1) => {
		setLoading(true);
		var response = await apiProduct.getProductsPaginated(page, filter);
		setLoading(false);

		if (response.ok) {
			setPaginator(response.data.paginator);
			setDinners(response.data.results);
		}
	};

	const EditSelectedProduct = (product) => {
		history.push({ pathname: "/product/edit", state: product });
	};

	const ShowDeleteSelectedProduct = (product) => {
		AppAlert.OptionsAlert(
			"Este producto se eliminará, y ya no estará disponible para el resto de operaciones, y se eliminará toda su información.",
			"Eliminar de todos modos"
		).then((response) => {
			if (response) {
				DeleteProduct(product.id);
			}
		});
	};

	const DeleteProduct = async (product_id) => {
		var response = await apiProduct.deleteProduct(product_id);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			GetProducts();
		}
	};

	useEffect(() => {
		GetProducts();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Lista de productos registrados:" />
				<BreadCrumb items={[{ label: "Productos" }, { label: "Lista de Productos Registrados", active: true }]} />
			</div>
			<div className="col-12">
				<TablePaginate
					buttons={buttons}
					headers={headers}
					rows={products}
					values={values}
					onSearchApi={(page) => GetProducts(page)}
					onChangeFilter={setFilter}
					paginator={paginator}
				/>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default ListProductsScreen;
