import React, { useState } from "react";
import AppTitle from "../../controls/AppTitle";
import AppSelect from "../../controls/AppSelect";
import { Link } from "react-router-dom";
import apiPerson from "../../../apis/person/apiPerson";
import AppAlert from "../../common/AppAlert";

function FormsScreen() {
	const [file, setFile] = useState(null);

	const items = [
		{
			label: "1.- Notificación Retiro de Medidor",
			value: 1,
		},
		{
			label: "2.- Acta de Retiro de Medidor de Agua Potable",
			value: 2,
		},
		{
			label: "3.- Acta de Instalacion del Medidor de Agua",
			value: 3,
		},
	];
	const [option, setOption] = useState({});

	const onChangeFile = (file) => {
		setFile(file);
	};

	const uploadfile = async () => {
		const formData = new FormData();
		formData.append("myfile", file, file.name);
		const response = await apiPerson.upload(formData);
		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
		}
	};

	return (
		<div>
			<AppTitle text="Generar Formulario" />
			<AppSelect items={items} label="Escoge el formulario para generar:" onChange={(item) => setOption(item)}></AppSelect>
			<div className="m5 p-5">
				{option.value == 1 && (
					<Link className="btn btn-sm btn-primary" to={"/print-forms/withdrawal-notification"}>
						Generar Notificación Retiro de Medidor
					</Link>
				)}
				{option.value == 2 && (
					<Link className="btn btn-sm btn-primary" to={"/print-forms/withdrawal-act"}>
						Generar Acta de Retiro de Medidor de Agua Potable
					</Link>
				)}
				{option.value == 3 && (
					<Link className="btn btn-sm btn-primary" to={"/print-forms/installation-certificate"}>
						Generar Acta de Instalacion del Medidor de Agua
					</Link>
				)}
			</div>
			<div className="hiden">
				<input type="file" onChange={({ target }) => onChangeFile(target.files[0])}></input>
				<button onClick={uploadfile}>Subir</button>
			</div>
		</div>
	);
}

export default FormsScreen;
