import React, { useEffect, useState } from "react";
import apiDinner from "../../../apis/dinner/apiDinner";
import apiSupply from "../../../apis/supply/apiSupply";
import AppAlert from "../../common/AppAlert";
import AppLoading from "../../common/AppLoading";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";
import { useHistory, useLocation } from "react-router";

function SupplyCreateScreen() {
	const history = useHistory();
	const location = useLocation();

	const [id, setId] = useState(null);
	const [name, setName] = useState("");
	const [dinner, setDinner] = useState({});
	const [loading, setLoading] = useState(false);
	const [dinnerList, setDinnerList] = useState([]);
	const [errorsForm, setErrorsForm] = useState({});

	const UpdateSupply = async () => {
		const supply = {
			id: id,
			name: name,
			dinner_id: dinner.value,
		};

		setLoading(true);
		const response = await apiSupply.updateSupply(supply);
		setLoading(false);

		if (response.ok) {
			history.goBack();
			setErrorsForm({});
			AppAlert.SimpleAlert(response.message);
		} else {
			setErrorsForm(response);
		}
	};

	const GetDinners = async (dinner_id) => {
		const response = await apiDinner.getDinnersDropdown();

		if (response.ok) {
			const dinner = response.data.find((x) => x.value == dinner_id);
			setDinner(dinner);
			setDinnerList(response.data);
		}
	};

	useEffect(() => {
		const supply = location.state;
		setId(supply.id);
		setName(supply.name);

		GetDinners(supply.dinner);
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Edición de Insumos:" />
				<BreadCrumb items={[{ label: "Insumos" }, { label: "Edición de Insumo", active: true }]} />
			</div>
			<div className="card col-12">
				<div className="row card-body col-12">
					<div className="col-md-6">
						<AppInput
							label="Nombre del Insumo"
							type="text"
							defaultValue={name}
							onChange={({ target }) => setName(target.value)}
							placeholder="Nombre Insumo"
							errors={errorsForm.name}
						/>
					</div>
					<div className="col-6">
						<AppSelect label="Comedor" value={dinner} items={dinnerList} onChange={(dinner) => setDinner(dinner ? dinner : {})} errors={errorsForm.dinner_id} />
					</div>
					<div className="col-12 text-center my-4">
						<button className="btn btn-primary" onClick={UpdateSupply}>
							Editar Insumo
						</button>
					</div>
				</div>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default SupplyCreateScreen;
