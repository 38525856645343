import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import appColors from "../../config/appColors";
import AuthContext from "../../context/context";
import AppIcon from "../controls/AppIcon";

function Header({ menu = [], user = {} }) {
	const authContext = useContext(AuthContext);
	const history = useHistory();

	const Redirect = (route) => {
		history.push(route);
	};

	const CloseSession = () => {
		history.push("/");
		localStorage.removeItem("userLogged");
		authContext.setUserLogged(null);
	};

	return (
		<div className="d-flex justify-content-around bg-white shadow mt-4 rounded pb-1 sticky-top row">
			<button className="btn text-primary" onClick={() => Redirect("/")}>
				<img src="../imgs/logo-RyO.jpg" style={{ width: 40, alignSelf: "center" }} className="card-img-top shadow rounded-circle" />
				<br />
				<span className="small">{user ? user.customer : null}</span>
			</button>
			{menu.map((item, index) => {
				return (
					<div key={index} className="btn-group" role="group">
						<button id="btnGroupDrop1" className="btn" data-toggle="dropdown">
							<div className="row">
								<div className="col-md-12 ">
									<AppIcon iconName={item.icon} />
								</div>
								<div className="col-md-12">
									<span className="small text-primary">{item.title}</span>
								</div>
							</div>
						</button>
						<div className="dropdown-menu" aria-labelledby="navbarDropdown">
							{item.submenu.map((submenu, index) => {
								return (
									<button key={index} className="btn" onClick={() => Redirect(submenu.route)}>
										<div className="row">
											<div className="col-md-12">
												<AppIcon iconName={submenu.icon} />
											</div>
											<div className="col-md-12">
												<span className="small text-primary">{submenu.label}</span>
											</div>
										</div>
										<div className="dropdown-divider" />
									</button>
								);
							})}
						</div>
					</div>
				);
			})}
			<button className="btn" onClick={CloseSession}>
				<div className="row">
					<div className="col-md-12">
						<AppIcon iconName={"door-open"} color={appColors.red_05} />
					</div>
					<div className="col-md-12">
						<span className="small text-danger">Salir</span>
					</div>
				</div>
			</button>
		</div>
	);
}

export default Header;
