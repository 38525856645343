import React, { useEffect, useState } from "react";
import apiPerson from "../../../apis/person/apiPerson";
import appColors from "../../../config/appColors";
import helperDate from "../../../helpers/helperDate";
import AppAlert from "../../common/AppAlert";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import AppCalendar from "../../controls/AppCalendar";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppSubTitle from "../../controls/AppSubTitle";
import AppTitle from "../../controls/AppTitle";

function UpdatePersonScreen() {
	const [notificationDate, setNotificationDate] = useState(new Date());
	const [errors, setErrors] = useState([]);
	const [person, setPerson] = useState({});
	const [inscriptionCode, setInscriptionCode] = useState("");
	const [observations, setObservations] = useState("");
	const [withoutConditions, setWithoutConditions] = useState(false);
	const [inCharged, setInCharged] = useState({});
	const [listInChargeds, setListInChargeds] = useState([]);

	useEffect(() => {
		GetInChargeds();
		return () => {};
	}, []);

	const SearchPerson = async () => {
		const response = await apiPerson.getPersonByInscriptionCode(inscriptionCode);

		if (response.ok) {
			setPerson(response.data);
			ChangeNotificationDate(response.data);
		}
	};

	const GetInChargeds = async () => {
		const response = await apiPerson.getInChargedsDropdown();

		if (response.ok) {
			setListInChargeds(response.data);
		}
	};

	const ChangeNotificationDate = async (person) => {
		const data = {
			observations: observations,
			without_conditions: withoutConditions,
			person_id: person ? person.id : null,
			notification_in_charged_id: inCharged ? inCharged.value : null,
			notification_date: helperDate.parseToServerFormat(notificationDate),
			notification_date_system: helperDate.getCurrentToServer(),
		};

		const response = await apiPerson.changeNotificationDate(data);

		if (response.ok) {
			AppAlert.AlertTopLeft(response.message);
			setPerson({});
			setErrors([]);
			setObservations("");
			setInscriptionCode("");
		} else {
			setErrors(response);
		}
	};

	const onChangeInscriptionCode = (event) => {
		if (event.key === "Enter") {
			SearchPerson();
		}
	};

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Notificacion de Personas" />
				<AppBreadCrumb items={[{ label: "Personas" }, { label: "Notificacion de personas", active: true }]} />
			</div>
			<div className="col-md-6 p-3">
				<AppInput
					errors={errors.person_id}
					type="number"
					value={inscriptionCode}
					label="Código de Inscripción:"
					placeholder="Código Inscripción ... "
					onKeyPress={(event) => onChangeInscriptionCode(event)}
					onChange={({ target }) => setInscriptionCode(target.value)}
				/>
			</div>
			<div className="col-md-6 mt-4 p-3">
				<button className="btn btn-primary w-100" onClick={SearchPerson}>
					BUSCAR
				</button>
			</div>
			<div className="col-md-12">
				<div className="card">
					<div className="card-body">
						<AppTitle text="Datos del Usuario:" />
						<AppSubTitle color={appColors.green_05} text={"USUARIO: " + person ? person.person : null} />
						<AppSubTitle text={"INSCRIPCION: " + person ? person.inscription_code : null} />
						<AppSubTitle text={"DIRECCION: " + person ? person.address : null} />
					</div>
				</div>
			</div>
			<div className="col-md-12 row form-floating p-3">
				<div className="col-md-6">
					<label htmlFor="observations">Observaciones: </label>
					<textarea
						className="form-control"
						onChange={({ target }) => setObservations(target.value)}
						placeholder="Observaciones ..."
						id="observations"
						value={observations}
						style={{ height: 100 }}
					></textarea>
				</div>
				<div className="col-md-6 text-center">
					<div className="form-check col-md-12 my-2">
						<hr />
						<input
							className="form-check-input"
							onChange={({ target }) => {
								setObservations("");
								setWithoutConditions(target.checked);
							}}
							type="checkbox"
							id="flexCheckIndeterminate"
						/>
						<label className="form-check-label" htmlFor="flexCheckIndeterminate">
							No cuenta con condiciones para instalacion
						</label>
						<br />
						<small className="text-danger">Solo marcar cuando no existe posibilidad de realizar la instalacion.</small>
						<hr />
					</div>
				</div>
			</div>
			<div className="col-md-3 p-3">
				<AppCalendar label="Fecha de Notificacion: " errors={errors.notification_date} onChange={setNotificationDate} value={notificationDate} />
			</div>
			<div className="col-md-3 p-3">
				<AppSelect
					label="Notificador"
					value={inCharged}
					errors={errors.notification_in_charged_id}
					items={listInChargeds}
					onChange={setInCharged}
					placeholder="Gasfitero"
				/>
			</div>
			<div className="col-md-6 mt-4 p-3">
				<button className="btn btn-success w-100" onClick={ChangeNotificationDate}>
					NOTIFICAR
				</button>
			</div>
		</div>
	);
}

export default UpdatePersonScreen;
