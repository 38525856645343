import React, { useEffect } from "react";
import Select from "react-select";

const PICK_ELEMENT = "Seleccione un elemento ... ";

function AppSelect({ label, onChange, isMulti, isDisabled, onInputChange, items = [], errors = [], defaultValue, placeholder = PICK_ELEMENT, value }) {
	return (
		<div>
			<label htmlFor="select">{label}</label>
			<Select
				isDisabled={isDisabled}
				isMulti={isMulti}
				value={value}
				options={items}
				onChange={onChange}
				placeholder={placeholder}
				defaultValue={defaultValue}
				onInputChange={onInputChange}
				isClearable={true}
				maxMenuHeight={400}
			/>
			{errors.map((error, index) => {
				return (
					<span key={index} className="text-danger small">
						{error}
					</span>
				);
			})}
		</div>
	);
}

export default AppSelect;
