import React from "react";
import BreadCrumb from "../../common/AppBreadCrumb";
import AttendanceCreateForm from "../../components/AttendanceCreateForm";
import AppTitle from "../../controls/AppTitle";

function AttendanceScreen() {
	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Registro de Asistencias:" />
				<BreadCrumb items={[{ label: "Asistencias" }, { label: "Registro de Asistencias", active: true }]} />
			</div>
			<div className="col-12">
				<AttendanceCreateForm />
			</div>
		</div>
	);
}

export default AttendanceScreen;
