import React, { useEffect, useState } from "react";
import AppAlert from "../../common/AppAlert";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import apiMeasurer from "../../../apis/measurer/apiMeasurer";
import AppCalendar from "../../controls/AppCalendar";
import AppTitle from "../../controls/AppTitle";
import helperDate from "../../../helpers/helperDate";

function MeasurerCreateScreen() {
	const [errors, setErrors] = useState([]);
	const [initMeasurerCode, setInitMeasurerCode] = useState(19);
	const [endMeasurerCode, setEndMeasurerCode] = useState(19);
	const [observations, setObservations] = useState("");
	const [arrivalDate, setArrivalDate] = useState(new Date());

	useEffect(() => {
		return () => {};
	}, []);

	const CreateNewMeasurers = async () => {
		const data = {
			observations: observations,
			first_code: initMeasurerCode,
			last_code: endMeasurerCode,
			arrival_date: helperDate.parseToServerFormat(arrivalDate),
		};

		const response = await apiMeasurer.createNewMeasurer(data);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			setErrors([]);
			setObservations("");
			setInitMeasurerCode(19);
			setEndMeasurerCode(19);
		} else {
			setErrors(response);
		}
	};

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Registro de Medidores" />
				<AppBreadCrumb items={[{ label: "Medidores" }, { label: "Registro de medidores", active: true }]} />
			</div>
			<div className="col-md-5 p-3">
				<AppInput
					errors={errors.first_code}
					type="number"
					value={initMeasurerCode}
					label="Número Medidor Inicial:"
					onChange={({ target }) => setInitMeasurerCode(target.value)}
				/>
			</div>
			<div className="col-md-5 p-3">
				<AppInput
					errors={errors.last_code}
					type="number"
					value={endMeasurerCode}
					label="Número Medidor Final:"
					onChange={({ target }) => setEndMeasurerCode(target.value)}
				/>
			</div>
			<div className="col-md-2 mt-4 p-3">
				<button className="btn btn-primary w-100" onClick={CreateNewMeasurers}>
					REGISTRAR CAJA
				</button>
			</div>
			<div className="col-md-12 m-2 text-center">
				<AppTitle text={parseInt(endMeasurerCode) - parseInt(initMeasurerCode) + 1 + " Medidores a Registrar"} />
			</div>
			<div className="col-md-12 text-center">
				<AppCalendar label="Fecha de Llegada:" onChange={setArrivalDate} value={arrivalDate} />
			</div>
			<div className="col-md-12 form-floating p-3">
				<label>Observaciones: </label>
				<textarea
					className="form-control"
					onChange={({ target }) => setObservations(target.value)}
					placeholder="Observaciones ..."
					id="observations"
					value={observations}
					style={{ height: 100 }}
				></textarea>
				<small className="small text-danger">{errors.observations}</small>
			</div>
		</div>
	);
}

export default MeasurerCreateScreen;
