import React, { useEffect, useState } from "react";
import AppListItem from "../controls/AppListItem";

function AssignationList({ items = [], onButtonClick }) {
	const [listCodes, setListCodes] = useState([]);

	useEffect(() => {
		setListCodes(items);
		return () => {};
	}, [listCodes]);

	return (
		<div>
			{listCodes.map((code, index) => {
				return <AppListItem key={index} title={code.person} subtitle={code.inscription_code} btnText="Quitar Elemento" onClick={() => onButtonClick(index)} />;
			})}
		</div>
	);
}

export default AssignationList;
