import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function AppLoading({ visible }) {
	if (visible)
		return (
			<div
				className="d-flex align-items-center"
				style={{ backgroundColor: "#fdfefe80", position: "absolute", left: 0, top: 0, display: "block", zIndex: 100, width: "100%", height: "100%" }}
			>
				<div style={{ margin: "auto" }}>
					<Loader type="Oval" color="#212f3c" visible={visible} height={150} width={150} />
				</div>
			</div>
		);
	else return null;
}

export default AppLoading;
