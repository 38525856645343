import React, { useEffect, useState } from "react";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";
import AppCalendarRange from "../../controls/AppCalendarRange";
import AppLoading from "../../common/AppLoading";
import apiService from "../../../apis/service/apiService";
import apiWorkUnit from "../../../apis/workUnit/apiWorkUnit";
import apiContractor from "../../../apis/contractor/apiContractor";
import apiDinner from "../../../apis/dinner/apiDinner";
import helperDate from "../../../helpers/helperDate";
import TablePaginate from "../../components/TablePaginate";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppIcon from "../../controls/AppIcon";
import appColors from "../../../config/appColors";

function AttendanceReportScreen() {
	const headers = ["Fecha", "Usuario", "Persona", "Servicio", "Comedor", "Contratista", "Unidad", "Precio"];
	const values = ["attended_date", "user", "person", "service", "dinner_point", "contractor", "work_unit", "price"];

	const [initDate, setInitDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const [service, setService] = useState({});
	const [serviceList, setServiceList] = useState([]);

	const [workUnitList, setWorkUnitList] = useState([]);

	const [contractors, setContractors] = useState([]);
	const [contractorList, setContractorList] = useState([]);

	const [dinnerPoint, setDinnerPoint] = useState({});
	const [dinnerPointList, setDinnerPointList] = useState([]);

	const [errors, setErrors] = useState({});
	const [totalAttendances, setTotalAttendances] = useState({});
	const [loading, setLoading] = useState(false);

	var initDate1 = new Date();
	var endDate1 = new Date();

	const onChangeDates = async (changeDate) => {
		const [start, end] = changeDate;

		initDate1 = start;
		endDate1 = end;

		setEndDate(end);
		setInitDate(start);

		if (end) {
			setEndDate(end);
			setInitDate(start);

			GetAttendancesToReport();
		}
	};

	useEffect(() => {
		initDate1 = initDate;
		endDate1 = endDate;

		GetAttendancesToReport();
		return () => {};
	}, [dinnerPoint, service, contractors]);

	useEffect(() => {
		GetServices();
		GetWorkUnits();
		GetContractors();
		GetDinnerPoints();
		return () => {};
	}, []);

	const GetServices = async () => {
		setLoading(true);
		const response = await apiService.getServicesDropdown();
		setLoading(false);
		if (response.ok) {
			setServiceList(response.data);
		}
	};

	const GetWorkUnits = async () => {
		setLoading(true);
		const response = await apiWorkUnit.getWorkUnitsDropdown();
		setLoading(false);
		if (response.ok) {
			setWorkUnitList(response.data);
		}
	};

	const GetContractors = async () => {
		setLoading(true);
		const response = await apiContractor.getContractorsDropdown();
		setLoading(false);
		if (response.ok) {
			setContractorList(response.data);
		}
	};

	const GetDinnerPoints = async () => {
		setLoading(true);
		const response = await apiDinner.getDinnerPointsDropdown();
		setLoading(false);

		if (response.ok) {
			setDinnerPointList(response.data);
		}
	};

	const GetAttendancesToReport = async () => {
		setLoading(true);
		for (let index = 0; index < workUnitList.length; index++) {
			const workUnit = workUnitList[index];
			await GetFilterAttendances(1, workUnit);
		}
		setLoading(false);
	};

	const GetFilterAttendances = async (page = 1, workUnit) => {
		const data = {
			work_unit: workUnit.value,
			initDate: helperDate.parseToServerFormat(initDate1),
			endDate: helperDate.parseToServerFormat(endDate1),
			contractor: contractors.map((x) => x.value),
			service: service ? service.value : null,
			dinner_point: dinnerPoint ? dinnerPoint.value : null,
		};
		const response = await apiService.getReportAttendances(page, data);

		if (response.ok) {
			var previousState = totalAttendances;
			previousState[workUnit.label] = response.data;

			setTotalAttendances(previousState);
		} else {
			setErrors(response);
		}
	};

	const DownloadReport = async (workUnit, workUnit_name) => {
		initDate1 = initDate;
		endDate1 = endDate;

		const data = {
			work_unit: workUnit.value,
			initDate: helperDate.parseToServerFormat(initDate1),
			endDate: helperDate.parseToServerFormat(endDate1),
			contractor: contractors.map((x) => x.value),
			service: service ? service.value : null,
			dinner_point: dinnerPoint ? dinnerPoint.value : null,
		};

		setLoading(true);
		apiService.downloadReport(data, "reportes de asistencias " + workUnit_name + ".xls");
		setLoading(false);
	};

	const SearchApi = (page, workUnit) => {
		GetFilterAttendances(page, workUnit);
	};

	return (
		<div className="row">
			<div className="col-md-12">
				<AppTitle text="Reporte de Asistencias: " />
				<BreadCrumb items={[{ label: "Asistencias" }, { label: "Reporte de Asistencias", active: true }]} />
			</div>
			<div className="col-md-12 card">
				<div className="card-body row">
					<div className="col-md-6 text-center my-5">
						<AppCalendarRange label="Rango del Reporte: " startDate={initDate} endDate={endDate} onChange={onChangeDates} />
					</div>
					<div className="col-md-6 d-flex align-items-center flex-wrap">
						<div className="col-md-12">
							<AppSelect label="Punto de Servicio:" onChange={setDinnerPoint} items={dinnerPointList} errors={errors.dinner_point} />
						</div>
						<div className="col-md-12">
							<AppSelect label="Tipo de Servicio:" onChange={setService} items={serviceList} errors={errors.service} />
						</div>
						<div className="col-md-12">
							<AppSelect label="Contratista:" isMulti={true} onChange={setContractors} items={contractorList} errors={errors.contractor} />
						</div>
					</div>
				</div>
			</div>
			{workUnitList.map((workUnit, index) => {
				return (
					<div key={index} className="col-12">
						<AppTitle text={"Asistencias para " + workUnit.label + ":"} />
						<TablePaginate
							headers={headers}
							values={values}
							searchable={false}
							onSearchApi={(page) => SearchApi(page, workUnit)}
							rows={totalAttendances[workUnit.label] ? totalAttendances[workUnit.label].results : []}
							paginator={totalAttendances[workUnit.label] ? totalAttendances[workUnit.label].paginator : {}}
						/>
						{totalAttendances[workUnit.label] && totalAttendances[workUnit.label].results.length != 0 && (
							<div className="m-2">
								<button className="btn btn-sm btn-link text-decoration-none" onClick={() => DownloadReport(workUnit, workUnit.label)}>
									<AppIcon iconName="arrow-alt-circle-down" color={appColors.green_04} />
									<span className="text-success">Descargar Reporte {workUnit.label}</span>
								</button>
								<a
									className="btn btn-sm btn-link text-decoration-none"
									href={"mailto:luisrosasosores14@outlook.es;?subject=Envío de reporte de asistencias " + workUnit.label}
								>
									<AppIcon iconName="envelope-square" color={appColors.red_04} />
									<span className="text-danger">Abrir Correo</span>
								</a>
							</div>
						)}
					</div>
				);
			})}
			<AppLoading visible={loading} />
		</div>
	);
}

export default AttendanceReportScreen;
