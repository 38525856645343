import React, { useEffect, useState } from "react";
import apiPerson from "../../../apis/person/apiPerson";
import appColors from "../../../config/appColors";
import helperDate from "../../../helpers/helperDate";
import AppAlert from "../../common/AppAlert";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppSubTitle from "../../controls/AppSubTitle";
import AppTitle from "../../controls/AppTitle";

function ObservationPersonScreen() {
	const [errors, setErrors] = useState([]);
	const [person, setPerson] = useState({});
	const [inscriptionCode, setInscriptionCode] = useState("");
	const [observations, setObservations] = useState("");
	const [observation, setObservation] = useState({});
	const [listInChargeds, setListInChargeds] = useState([]);

	useEffect(() => {
		GetObservations();
		return () => {};
	}, []);

	const SearchPerson = async () => {
		const response = await apiPerson.getPersonByInscriptionCode(inscriptionCode);

		if (response.ok) {
			setPerson(response.data);
		}
	};

	const GetObservations = async () => {
		const response = await apiPerson.getObservationsDropdown();

		if (response.ok) {
			setListInChargeds(response.data);
		}
	};

	const ObservatePerson = async () => {
		const data = {
			observations: observations,
			person_id: person ? person.id : null,
			observation_id: observation ? observation.value : null,
		};

		const response = await apiPerson.observatePerson(data);

		if (response.ok) {
			AppAlert.AlertTopLeft(response.message);
			setPerson({});
			setErrors([]);
			setObservations("");
			setInscriptionCode("");
		} else {
			setErrors(response);
		}
	};

	const onChangeInscriptionCode = (event) => {
		if (event.key === "Enter") {
			SearchPerson();
		}
	};

	const onChangeObservations = (event) => {
		if (event.key === "Enter") {
			ObservatePerson();
		}
	};

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Observacion de Personas" />
				<AppBreadCrumb items={[{ label: "Personas" }, { label: "Observacion de personas", active: true }]} />
			</div>
			<div className="col-md-6 p-3">
				<AppInput
					errors={errors.person_id}
					type="number"
					value={inscriptionCode}
					label="Código de Inscripción:"
					placeholder="Código Inscripción ... "
					onKeyPress={(event) => onChangeInscriptionCode(event)}
					onChange={({ target }) => setInscriptionCode(target.value)}
				/>
			</div>
			<div className="col-md-6 mt-4 p-3">
				<button className="btn btn-primary w-100" onClick={SearchPerson}>
					BUSCAR
				</button>
			</div>
			<div className="col-md-4">
				<div className="card">
					<div className="card-body">
						<AppTitle text="Datos del Usuario:" />
						<AppSubTitle color={appColors.green_05} text={"USUARIO: " + person ? person.person : null} />
						<AppSubTitle text={"INSCRIPCION: " + person ? person.inscription_code : null} />
						<AppSubTitle text={"DIRECCION: " + person ? person.address : null} />
					</div>
				</div>
			</div>

			<div className="col-md-3 p-3">
				<AppSelect
					label="Tipo de Observacion"
					value={observation}
					errors={errors.observation_id}
					items={listInChargeds}
					onChange={setObservation}
					placeholder="Observacion"
				/>
			</div>
			<div className="col-md-3 row form-floating p-3">
				<textarea
					className="form-control"
					onChange={({ target }) => setObservations(target.value)}
					placeholder="Observaciones ..."
					id="observations"
					onKeyPress={(event) => onChangeObservations(event)}
					value={observations}
					style={{ height: 100 }}
				></textarea>
				<small className="small text-danger">{errors.observations}</small>
			</div>

			<div className="col-md-2 mt-4 p-3">
				<button className="btn btn-success w-100" onClick={ObservatePerson}>
					Observar
				</button>
			</div>
		</div>
	);
}

export default ObservationPersonScreen;
