import React, { useEffect, useState } from "react";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";
import AppCalendarRange from "../../controls/AppCalendarRange";
import AppLoading from "../../common/AppLoading";
import apiContractor from "../../../apis/contractor/apiContractor";
import helperDate from "../../../helpers/helperDate";
import TablePaginate from "../../components/TablePaginate";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppIcon from "../../controls/AppIcon";
import appColors from "../../../config/appColors";
import AppInput from "../../controls/AppInput";
import apiPerson from "../../../apis/person/apiPerson";
import apiMeasurer from "../../../apis/measurer/apiMeasurer";

function SedacajReportsScreen() {
	const headers = [
		"Ins",
		"Med Ant",
		"Med Nv",
		"Not",
		"Inst",
		"Form",
		"Usr",
		"Dir",
		"Sct",
		"Mz",
		"Activ",
		"Valr",
		"Notif",
		"Gasfi",
		"Obsv 1",
		"Obsv 2",
		"Obsv 3",
		"Lt",
	];
	const values = [
		"inscription_code",
		"measurer_code",
		"code",
		"notification_date",
		"instalation_date",
		"instalation_formal_date",
		"person",
		"address",
		"contractor_code",
		"block",
		"measurer_states",
		"valorized",
		"notification_in_charged",
		"in_charged",
		"observations",
		"second_observations",
		"thrid_observations",
		"patron",
	];

	const [initDate, setInitDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const [address, setAddress] = useState("");

	const [contractor, setContractor] = useState({});
	const [contractorList, setContractorList] = useState([]);

	const [observation, setObservation] = useState({});
	const [observationList, setObservationList] = useState([]);

	const [notificator, setNotificator] = useState({});
	const [notificatorList, setNotificatorList] = useState([]);

	const [gasfitter, setGasfitter] = useState({});
	const [gasfitterList, setGasfitterList] = useState([]);

	const [personLevel, setPersonLevel] = useState(null);
	const [block, setBlock] = useState("");
	const [lastNames, setLastNames] = useState("");
	const [newMeasurer, setNewMeasurer] = useState("");
	const [oldMeasurer, setOldMeasurer] = useState("");
	const [inscriptionCode, setInscriptionCode] = useState(null);

	const [paginator, setPaginator] = useState({});
	const [listPersons, setListPersons] = useState([]);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	const [statesList, setStatesList] = useState([]);
	const [applyStatesFilters, setApplyStatesFilters] = useState(false);
	const [states, setStates] = useState([]);

	const onChangeDates = async (changeDate) => {
		const [start, end] = changeDate;
		setEndDate(end);
		setInitDate(start);
	};

	useEffect(() => {
		GetSectors();
		GetObservations();
		GetGasfitters();
		GetMeterStates();
		GetNotificators();
		return () => {};
	}, []);

	const GetNotificators = async () => {
		const response = await apiPerson.getInChargedsDropdown();

		if (response.ok) {
			setNotificatorList(response.data);
		}
	};

	const GetObservations = async () => {
		const response = await apiPerson.getObservationsDropdown();

		if (response.ok) {
			setObservationList(response.data);
		}
	};

	const GetMeterStates = async () => {
		const response = await apiMeasurer.getMeasurerStatesDropDown();

		if (response.ok) {
			setStates(response.data);
		}
	};

	const GetGasfitters = async () => {
		const response = await apiPerson.getInChargedsDropdown();

		if (response.ok) {
			setGasfitterList(response.data);
		}
	};

	const GetSectors = async () => {
		setLoading(true);
		const response = await apiContractor.getContractorsDropdown();
		setLoading(false);
		if (response.ok) {
			setContractorList(response.data);
		}
	};

	const GetFilterPersons = async (page = 1) => {
		setListPersons([]);
		let states = "";
		statesList.map((state) => (states += state.label + " "));
		states = states.trim();

		const data = {
			initDate: helperDate.parseToServerFormat(initDate),
			endDate: helperDate.parseToServerFormat(endDate),
			contractor: contractor ? contractor.value : null,
			notificator: notificator ? notificator.value : null,
			gasfitter: gasfitter ? gasfitter.value : null,
			inscription_code: inscriptionCode,
			last_names: lastNames,
			address: address,
			newMeasurer: newMeasurer,
			oldMeasurer: oldMeasurer,
			person_level: personLevel ? personLevel.value : null,
			block: block,
			states: states,
			byStates: applyStatesFilters,
			observation: observation ? observation.value : null,
		};

		const response = await apiPerson.getReportSedacaj(page, data);

		if (response.ok) {
			var list = response.data.results;

			// for (let index = 0; index < list.length; index++) {
			// 	const element = list[index];

			// 	const response2 = await apiPerson.getPersonByCodeSECONDSERVER(element.inscription_code);

			// 	if (response2.ok) {
			// 		element.valorized = response2.data.activities;
			// 	} else element.valorized = "No valorizado";
			// }
			setListPersons(list);
			setPaginator(response.data.paginator);
		} else {
			setErrors(response);
		}
	};

	const onKeyPress = (event) => {
		if (event.key === "Enter") {
			GetFilterPersons();
		}
	};

	const DownloadReport = async () => {
		const data = {
			initDate: helperDate.parseToServerFormat(initDate),
			endDate: helperDate.parseToServerFormat(endDate),
			contractor: contractor ? contractor.value : null,
			notificator: notificator ? notificator.value : null,
			gasfitter: gasfitter ? gasfitter.value : null,
			inscription_code: inscriptionCode,
			address: address,
			last_names: lastNames,
			newMeasurer: newMeasurer,
			oldMeasurer: oldMeasurer,
			person_level: personLevel ? personLevel.value : null,
			block: block,
			states: states,
			byStates: applyStatesFilters,
			observation: observation ? observation.value : null,
		};

		setLoading(true);
		apiPerson.downloadReportPersons(data, "Reporte Completo.xls");
		setLoading(false);
	};

	const DownloadReportToSedacaj = async () => {
		const data = {
			initDate: helperDate.parseToServerFormat(initDate),
			endDate: helperDate.parseToServerFormat(endDate),
			contractor: contractor ? contractor.value : null,
			notificator: notificator ? notificator.value : null,
			gasfitter: gasfitter ? gasfitter.value : null,
			inscription_code: inscriptionCode,
			address: address,
			last_names: lastNames,
			newMeasurer: newMeasurer,
			oldMeasurer: oldMeasurer,
			person_level: personLevel ? personLevel.value : null,
			block: block,
			states: states,
			byStates: applyStatesFilters,
			observation: observation ? observation.value : null,
		};

		setLoading(true);
		apiPerson.downloadReportSedacaj(data, "Reporte para Sedacaj.xls");
		setLoading(false);
	};

	return (
		<div className="row">
			<div className="col-md-12">
				<AppTitle text="Reporte de Personas: " />
				<BreadCrumb items={[{ label: "Personas" }, { label: "Reporte de Personas", active: true }]} />
			</div>
			<div className="col-md-12 card">
				<div className="card-body row">
					<div className="col-md-6 text-center my-5">
						<AppCalendarRange label="Rango del Reporte: " startDate={initDate} endDate={endDate} onChange={onChangeDates} />
					</div>
					<div className="col-md-6 d-flex align-items-center flex-wrap">
						<div className="col-md-3 my-1">
							<div className="form-check">
								<input
									onChange={() => setApplyStatesFilters(!applyStatesFilters)}
									className="form-check-input"
									type="checkbox"
									value=""
									id="flexCheckIndeterminate"
								/>
								<label className="form-check-label" htmlFor="flexCheckIndeterminate">
									Filtro por actividad
								</label>
							</div>
						</div>
						<div className="col-md-3 my-1">
							<AppSelect isMulti={true} isDisabled={!applyStatesFilters} items={states} onChange={setStatesList} label="Actividades" />
						</div>
						<div className="col-md-6 my-1">
							<AppSelect items={observationList} onChange={setObservation} label="Observacion" />
						</div>
						<div className="col-md-6 my-1">
							<AppSelect
								label="Nivel:"
								onChange={setPersonLevel}
								items={[
									{ label: "No Notificados", value: "not_notificated" },
									{ label: "No Instalados", value: "not_instalated" },
									{ label: "Notificados", value: "notification_date" },
									{ label: "Sin notificar y sin instalar", value: "not_notification_and_not_instalation" },
									{ label: "Sin notificar pero instalados", value: "not_notification_but_instalation" },
									{ label: "Notificados pero sin instalar", value: "notification_and_not_instalation" },
									{ label: "Notificados x Dia", value: "notification_by_day" },
									{ label: "Instalados / Formal", value: "instalation_by_day_formal" },
									{ label: "Instalados", value: "instalation_date" },
									{ label: "Observados", value: "observed" },
								]}
								errors={errors.dinner_point}
							/>
						</div>
						<div className="col-md-6 my-1">
							<AppSelect label="Sector:" onChange={setContractor} items={contractorList} errors={errors.contractor} />
						</div>
						<div className="col-md-6 my-1">
							<AppSelect label="Notificador:" onChange={setNotificator} items={notificatorList} errors={errors.contractor} />
						</div>
						<div className="col-md-6 my-1">
							<AppSelect label="Gasfitero:" onChange={setGasfitter} items={gasfitterList} errors={errors.contractor} />
						</div>
						<div className="col-md-6 my-1">
							<AppInput label="Manzana: " onKeyPress={(event) => onKeyPress(event)} onChange={({ target }) => setBlock(target.value)} />
						</div>
						<div className="col-md-6 my-1">
							<AppInput label="Direccion: " onKeyPress={(event) => onKeyPress(event)} onChange={({ target }) => setAddress(target.value)} />
						</div>
						<div className="col-md-6 my-1">
							<AppInput label="Medidor Nuevo: " onKeyPress={(event) => onKeyPress(event)} onChange={({ target }) => setNewMeasurer(target.value)} />
						</div>
						<div className="col-md-6 my-1">
							<AppInput label="Medidor Antiguo: " onKeyPress={(event) => onKeyPress(event)} onChange={({ target }) => setOldMeasurer(target.value)} />
						</div>
						<div className="col-md-6 my-1">
							<AppInput label="Apellidos: " onKeyPress={(event) => onKeyPress(event)} onChange={({ target }) => setLastNames(target.value)} />
						</div>
						<div className="col-md-6 my-1">
							<AppInput label="Codigo de Inscripcion: " onKeyPress={(event) => onKeyPress(event)} onChange={({ target }) => setInscriptionCode(target.value)} />
						</div>

						<div className="col-md-12 my-1">
							<button className="btn btn-primary w-100" onClick={() => GetFilterPersons()}>
								Filtrar
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="col-md-12">
				<TablePaginate
					headers={headers}
					values={values}
					searchable={false}
					onSearchApi={(page) => GetFilterPersons(page)}
					rows={listPersons}
					paginator={paginator}
				/>
				<div className="m-2">
					<button className="btn btn-sm btn-link text-decoration-none" onClick={() => DownloadReport()}>
						<AppIcon iconName="arrow-alt-circle-down" color={appColors.green_04} />
						<span className="text-success">Descargar Reporte Completo</span>
					</button>
				</div>
				<div className="m-2">
					<button className="btn btn-sm btn-link text-decoration-none" onClick={() => DownloadReportToSedacaj()}>
						<AppIcon iconName="arrow-alt-circle-down" color={appColors.red_04} />
						<span className="text-danger">Descargar Reporte para Sedacaj</span>
					</button>
				</div>
			</div>

			<AppLoading visible={loading} />
		</div>
	);
}

export default SedacajReportsScreen;
