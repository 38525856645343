const agregateCeroIfLessToTen = (value) => {
	value = parseInt(value);
	if (value < 10) return "0" + value;
	else return value;
};

export default {
	parseToLocalDateFormat(string) {
		try {
			var elements = string.split(" ");
			var dateSplit = elements[0].split("-");
			var date = dateSplit.reverse().join();
			date = date.replaceAll(",", "/");

			return date;
		} catch (error) {
			return error.message;
		}
	},
	parseToLocalDate(string) {
		try {
			var elements = string.split(" ");
			var dateSplit = elements[0].split("-");
			var year = dateSplit[0];
			var month = dateSplit[1];
			var day = dateSplit[2];
			return new Date(year, month, day);
		} catch (error) {
			return error.message;
		}
	},
	parseToLocalTimeFormat(string) {
		try {
			var elements = string.split(" ");
			var time = elements[0];

			return time;
		} catch (error) {
			return error.message;
		}
	},
	parseToLocalFormat(string) {
		try {
			var elements = string.split("T");
			var dateSplit = elements[0].split("-");
			var date = dateSplit.reverse().join();
			date = date.replaceAll(",", "/");

			var time = elements[1].replaceAll("Z", "");

			return time + " " + date;
		} catch (error) {
			return error.message;
		}
	},
	parseToServerFormat(string) {
		try {
			var timestamp = Date.parse(string);

			if (timestamp) {
				const date = new Date(timestamp);

				let hour = agregateCeroIfLessToTen(date.getHours());
				let minutes = agregateCeroIfLessToTen(date.getMinutes());
				let seconds = agregateCeroIfLessToTen(date.getSeconds());
				let day = date.getDate();
				let month = agregateCeroIfLessToTen(date.getMonth() + 1);
				let year = date.getFullYear();

				return `${year}.${month}.${day}.${hour}.${minutes}.${seconds}`;
			} else {
				return "Formato incorrecto.";
			}
		} catch (error) {
			return error.message;
		}
	},
	getCurrentString() {
		try {
			const timestamp = Date.now();

			const date = new Date(timestamp);

			let hour = agregateCeroIfLessToTen(date.getHours());
			let minutes = agregateCeroIfLessToTen(date.getMinutes());
			let seconds = agregateCeroIfLessToTen(date.getSeconds());
			let day = agregateCeroIfLessToTen(date.getDate());
			let month = agregateCeroIfLessToTen(date.getMonth() + 1);
			let year = date.getFullYear();

			return `${hour}:${minutes}:${seconds} ${day}/${month}/${year}`;
		} catch (error) {
			return error.message;
		}
	},
	getCurrentToServer() {
		try {
			const timestamp = Date.now();

			const date = new Date(timestamp);

			let hour = agregateCeroIfLessToTen(date.getHours());
			let minutes = agregateCeroIfLessToTen(date.getMinutes());
			let seconds = agregateCeroIfLessToTen(date.getSeconds());
			let day = date.getDate();
			let month = agregateCeroIfLessToTen(date.getMonth() + 1);
			let year = date.getFullYear();

			return `${year}.${month}.${day}.${hour}.${minutes}.${seconds}`;
		} catch (error) {
			return error.message;
		}
	},
};
