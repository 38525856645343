import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import apiMeasurer from "../../../apis/measurer/apiMeasurer";
import apiPerson from "../../../apis/person/apiPerson";
import appColors from "../../../config/appColors";
import helperDate from "../../../helpers/helperDate";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import TablePaginate from "../../components/TablePaginate";
import AppCalendarRange from "../../controls/AppCalendarRange";
import AppIcon from "../../controls/AppIcon";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";

function ReportMeasurerScreen() {
	const headers = ["Fecha Llegada", "Codigo", "Observacion"];
	const values = ["arrival_date", "code", "observation"];

	const [listMeasurerStates, setListMeasurerStates] = useState([
		{ label: "Por Fecha de Llegada", value: "arrival_date" },
		{ label: "Medidores instalados", value: "instalated_measurer" },
		{ label: "Medidores no instalados", value: "not_instalated_measurer" },
		{ label: "Medidores observados", value: "with_observations" },
	]);
	const [measurerState, setMeasurerState] = useState({});
	const [measurerList, setMeasurerList] = useState([]);
	const [paginator, setPaginator] = useState({});
	const [initDate, setInitDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const GetNewMeasurers = async (page = 1) => {
		const data = {
			measurer_state: measurerState ? measurerState.value : null,
			arrival_date: helperDate.parseToServerFormat(endDate),
		};
		var response = await apiMeasurer.getNewMeasurersPaginated(page, data);

		if (response.ok) {
			setPaginator(response.data.paginator);
			setMeasurerList(response.data.results);
		}
	};

	const onChangeDates = async (changeDate) => {
		const [start, end] = changeDate;
		setEndDate(end);
		setInitDate(start);
	};

	const OnEnterPress = async (event) => {
		if (event.key === "Enter") {
			GetNewMeasurers();
		}
	};

	const DownloadReport = async () => {
		const data = {
			measurer_state: measurerState ? measurerState.value : null,
			arrival_date: helperDate.parseToServerFormat(endDate),
		};

		apiMeasurer.downloadReport(data, "Reporte Medidor.xls");
	};

	useEffect(() => {
		GetNewMeasurers();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-md-12">
				<AppTitle text="Medidors Registrados:" />
				<AppBreadCrumb items={[{ label: "Medidores" }, { label: "Lista de Medidores", active: true }]} />
			</div>
			<div className="col-md-6 text-center row">
				<div className="col-md-12">
					<AppCalendarRange label="Rango del Reporte: " startDate={initDate} endDate={endDate} onChange={onChangeDates} />
				</div>
			</div>
			<div className="col-md-6">
				<div className="col-md-12">
					<AppSelect label="Estados:" onChange={setMeasurerState} items={listMeasurerStates} />
				</div>

				<div className="col-md-12 text-center p-2">
					<button className="btn btn-sm btn-primary w-50" onClick={() => GetNewMeasurers()}>
						Filtrar
					</button>
					<button className="btn btn-sm btn-link w-50 text-decoration-none" onClick={() => DownloadReport()}>
						<AppIcon iconName="arrow-alt-circle-down" color={appColors.red_04} />
						<span className="text-danger">Descargar Reporte</span>
					</button>
				</div>
			</div>
			<div className="col-md-12">
				<hr />
				<TablePaginate
					searchable={false}
					headers={headers}
					rows={measurerList}
					values={values}
					onSearchApi={(page) => GetNewMeasurers(page)}
					paginator={paginator}
				/>
			</div>
		</div>
	);
}

export default ReportMeasurerScreen;
