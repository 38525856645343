import React from "react";
import BreadCrumb from "../../common/AppBreadCrumb";
import UserCreateForm from "../../components/UserCreateForm";
import AppTitle from "../../controls/AppTitle";

function UserCreateScreen() {
	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Registro de Usuario:" />
				<BreadCrumb items={[{ label: "Usuarios" }, { label: "Registro de Usuarios:", active: true }]} />
			</div>
			<div className="col-12">
				<UserCreateForm />
			</div>
		</div>
	);
}

export default UserCreateScreen;
