import React from "react";
import appColors from "../../config/appColors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AppIcon({ iconName, color = appColors.blue_04, size = "1x", onClick }) {
	return (
		<div>
			<FontAwesomeIcon icon={["fas", iconName]} className="mx-1" onClick={onClick} color={color} size={size} />
		</div>
	);
}

export default AppIcon;
