import React from "react";
import { Route, Switch } from "react-router";
import LoginScreen from "./screens/login/LoginScreen";

export default function WelcomScreen() {
	return (
		<Switch>
			<Route path="/">
				<LoginScreen />
			</Route>
		</Switch>
	);
}
