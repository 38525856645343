import React, { useEffect, useState } from "react";
import TablePaginate from "../../components/TablePaginate";
import apiUser from "../../../apis/user/apiUser";
import AppAlert from "../../common/AppAlert";
import AppTitle from "../../controls/AppTitle";
import BreadCrumb from "../../common/AppBreadCrumb";
import appColors from "../../../config/appColors";
import { useHistory } from "react-router";
import apiPerson from "../../../apis/person/apiPerson";

function ListUsersScreen() {
	const headers = ["Usuario", "Permisos", "Rol", "Punto Comedor"];
	const values = ["email", "permissions", "rol_name", "dinner_point_name"];
	const buttons = [
		{ onClick: (user) => EditSelectedUser(user), style: "btn", iconName: "pen", iconColor: appColors.green_04 },
		{ onClick: (user) => ShowDeleteSelectedUser(user), style: "btn", iconName: "trash-alt", iconColor: appColors.red_04 },
	];

	const history = useHistory();
	const [users, setUsers] = useState([]);
	const [filter, setFilter] = useState("");
	const [paginator, setPaginator] = useState({});

	const GetUsers = async (page = 1) => {
		const response = await apiUser.getUsers(page, filter);

		if (response.ok) {
			setUsers(response.data.results);
			setPaginator(response.data.paginator);
		} else {
			AppAlert.SimpleAlert(response.message, "error");
		}
	};

	const EditSelectedUser = (user) => {
		history.push({ pathname: "/user/edit", state: { user: user } });
	};

	const ShowDeleteSelectedUser = (user) => {
		AppAlert.OptionsAlert(
			"Este usuario se eliminará, y ya no estará disponible para el resto de operaciones, y se eliminará toda su información.",
			"Eliminar de todos modos"
		).then((response) => {
			if (response) {
				DeleteUser(user.id);
			}
		});
	};

	const DeleteUser = async (user_id) => {
		var response = await apiPerson.deleteUser(user_id);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			GetUsers();
		}
	};

	useEffect(() => {
		GetUsers();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Lista de Usuarios Registrados:" />
				<BreadCrumb items={[{ label: "Usuarios" }, { label: "Lista de Usuarios:", active: true }]} />
			</div>
			<div className="col-12">
				<TablePaginate
					buttons={buttons}
					headers={headers}
					rows={users}
					values={values}
					onSearchApi={(page) => GetUsers(page)}
					onChangeFilter={setFilter}
					paginator={paginator}
				/>
			</div>
		</div>
	);
}

export default ListUsersScreen;
