import React, { Component } from "react";
import appColors from "../../../config/appColors";

export default class InstallationActToPrint extends Component {
	render() {
		const person = this.props.person;
		const date = this.props.date;

		return (
			<div className="table w-100" style={{ position: "absolute", top: 10 }}>
				<div className="table w-100" style={{ fontSize: 12, fontWeight: 600, letterSpacing: 4, fontFamily: "unset", top: 10, position: "absolute" }}>
					<span style={{ position: "absolute", left: 500, top: 40 }}>CONSULTAS:</span>
					<span style={{ position: "absolute", left: 650, top: 15 }}>-977 994 875</span>
					<span style={{ position: "absolute", left: 650, top: 30 }}>-920 114 410</span>
					<span style={{ position: "absolute", left: 650, top: 45 }}>-988 113 972</span>
					<span style={{ position: "absolute", left: 650, top: 60 }}>-938 162 233</span>
				</div>
				<span
					style={{ fontSize: 14, position: "absolute", fontWeight: 600, left: 480, color: appColors.red_05, top: 160, letterSpacing: 4, fontFamily: "unset" }}
				>
					{date}
				</span>
				<div className="table w-100" style={{ fontSize: 14, fontWeight: 600, letterSpacing: 4, fontFamily: "unset", top: 238, position: "absolute" }}>
					<span style={{ position: "absolute", left: 60 }}>{person.person}</span>
					<br />
					<span style={{ position: "absolute", left: 60 }}>{person.address}</span>
					<br />
					<span style={{ position: "absolute", left: 550 }}>{person.inscription_code}</span>
					<br />
					<br />
					<br />

					<span style={{ position: "absolute", left: 100 }}>{person.code}</span>
					<span style={{ position: "absolute", left: 550 }}>DN 15</span>
					<br />
					<span style={{ position: "absolute", left: 100 }}>ELSTER</span>
					<span style={{ position: "absolute", left: 550 }}>R-125</span>
					<br />
					<span style={{ position: "absolute", left: 100 }}>S 160</span>
					<span style={{ position: "absolute", left: 350 }}>2019</span>
					<span style={{ position: "absolute", left: 600 }}>2.5</span>
					<br />
					<span style={{ position: "absolute", left: 375 }}>0000</span>
					<br />
					<br />
					<br />
					<span style={{ position: "absolute", left: 535 }}>X</span>
					<br />
					<span style={{ position: "absolute", left: 535 }}>X</span>
					<br />
					<span style={{ position: "absolute", left: 535 }}>X</span>
					<br />
					<span style={{ position: "absolute", left: 60, top: 320 }}>
						Se instaló medidor, accesorios, llaves, {person.states_descriptions}. <br />
						Se adjunta acta de verificación inicial.
					</span>
					<span style={{ position: "absolute", left: 60, top: 380 }}>{person.measurer_states}</span>
					<br />
				</div>
			</div>
		);
	}
}
