import React, { useEffect, useState } from "react";
import AppAlert from "../common/AppAlert";
import apiDinner from "../../apis/dinner/apiDinner";
import apiRol from "../../apis/rol/apiRol";
import apiUser from "../../apis/user/apiUser";
import AppInput from "../controls/AppInput";
import AppSelect from "../controls/AppSelect";
import { useHistory, useLocation } from "react-router";

function UserEditForm() {
	const history = useHistory();
	const location = useLocation();

	const [email, setEmail] = useState("");
	const [id, setId] = useState(null);
	const [password, setPassword] = useState("");
	const [rol, setRol] = useState({});
	const [rols, setRols] = useState([]);
	const [dinnerPoint, setDinnerPoint] = useState({});
	const [dinnerList, setDinnerPointList] = useState([]);

	const [errorsForm, setErrorsForm] = useState({});

	const GetDinnerPoints = async () => {
		const response = await apiDinner.getDinnerPointsDropdown();
		if (response.ok) {
			setDinnerPointList(response.data);
		}
	};

	const GetRoles = async () => {
		const response = await apiRol.getRols();
		if (response.ok) {
			var list = [];
			response.data.map((item) => list.push({ label: item.name, value: item.id }));
			setRols(list);
		}
	};

	const UpdateUser = async () => {
		const user = {
			id: id,
			email: email,
			rol: rol.value,
			password: password,
			dinner_point: dinnerPoint.value,
		};

		var response = await apiUser.updateUser(user);

		if (response.ok) {
			setEmail(null);
			setPassword(null);
			setErrorsForm({});

			history.goBack();
			AppAlert.SimpleAlert(response.message);
		} else {
			setErrorsForm(response);
		}
	};

	useEffect(() => {
		const { user } = location.state;

		setRol({ label: user.rol_name, value: user.rol });
		setDinnerPoint({ label: user.dinner_point_name, value: user.dinner_point });

		setId(user.id);
		setEmail(user.email);

		GetRoles();
		GetDinnerPoints();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="card col-12">
				<div className="card-body row">
					<div className="col-6 form-group">
						<AppInput
							type="email"
							defaultValue={email}
							label="Correo Electrónico"
							onChange={({ target }) => setEmail(target.value)}
							placeholder="Correo Electrónico"
							errors={errorsForm.email}
						/>
					</div>
					<div className="col-6 form-group">
						<AppInput
							type="password"
							label="Contraseña"
							defaultValue={password}
							onChange={({ target }) => setPassword(target.value)}
							placeholder="Contraseña"
							errors={errorsForm.password}
						/>
					</div>
					<div className="col-6 form-group">
						<AppSelect
							label="Punto de Comedor"
							value={dinnerPoint}
							items={dinnerList}
							onChange={(dinnerPoint) => setDinnerPoint(dinnerPoint)}
							errors={errorsForm.dinner_point}
						/>
					</div>
					<div className="col-6 form-group">
						<AppSelect label="Rol" value={rol} items={rols} onChange={(rol) => setRol(rol)} errors={errorsForm.rol} />
					</div>
					<div className="text-center m-auto">
						<button className="btn btn-primary my-4" onClick={UpdateUser}>
							Editar Usuario
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserEditForm;
