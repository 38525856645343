import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faCode,
	faHighlighter,
	faHome,
	faStar,
	faCashRegister,
	faPlus,
	faTrashAlt,
	faPen,
	faFileInvoice,
	faConciergeBell,
	faUtensils,
	faUser,
	faHandsHelping,
	faBriefcase,
	faCodeBranch,
	faDoorOpen,
	faBusinessTime,
	faMale,
	faMapMarked,
	faAngleDoubleRight,
	faAngleDoubleLeft,
	faListUl,
	faAddressCard,
	faCarrot,
	faEnvelopeSquare,
	faDrumstickBite,
	faDownload,
	faCalendarPlus,
	faPrint,
	faCheckDouble,
	faAddressBook,
	faArrowDown,
	faArrowAltCircleDown,
	faWeight,
	faEnvelope,
	faEye,
	faAward,
} from "@fortawesome/free-solid-svg-icons";

library.add(
	faPrint,
	faCheckDouble,
	faHandsHelping,
	faEnvelope,
	faAddressBook,
	faDownload,
	faWeight,
	faAward,
	faAngleDoubleLeft,
	faDrumstickBite,
	faArrowAltCircleDown,
	faEye,
	faArrowDown,
	faEnvelopeSquare,
	faCarrot,
	faBusinessTime,
	faCode,
	faAddressCard,
	faMapMarked,
	faMale,
	faUtensils,
	faAngleDoubleRight,
	faConciergeBell,
	faFileInvoice,
	faListUl,
	faHighlighter,
	faHome,
	faCalendarPlus,
	faStar,
	faCashRegister,
	faPlus,
	faTrashAlt,
	faPen,
	faUser,
	faDoorOpen,
	faBriefcase,
	faCodeBranch
);
