import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import apiPerson from "../../../apis/person/apiPerson";
import appColors from "../../../config/appColors";
import AppAlert from "../../common/AppAlert";
import BreadCrumb from "../../common/AppBreadCrumb";
import TablePaginate from "../../components/TablePaginate";
import AppTitle from "../../controls/AppTitle";

function ListPersonsScreen() {
	const headers = ["Fecha", "Nombres", "Documento de Identidad", "Contratista", "Unidad de Trabajo"];
	const values = ["created", "person", "dni", "contractor", "work_unit"];
	const buttons = [
		{ onClick: (person) => EditSelectedPerson(person), style: "btn", iconName: "pen", iconColor: appColors.green_04 },
		{ onClick: (person) => ShowDeleteSelectedPerson(person), style: "btn", iconName: "trash-alt", iconColor: appColors.red_04 },
	];

	const history = useHistory();
	const [filter, setFilter] = useState("");
	const [paginator, setPaginator] = useState({});
	const [persons, setPersons] = useState([]);

	const GetPersons = async (page = 1) => {
		var response = await apiPerson.getPersonsPaginated(page, filter);
		if (response.ok) {
			setPaginator(response.data.paginator);
			setPersons(response.data.results);
		} else {
			AppAlert.SimpleAlert("Error", "error");
		}
	};

	const EditSelectedPerson = (person) => {
		history.push({ pathname: "/person/edit", state: person });
	};

	const ShowDeleteSelectedPerson = (person) => {
		AppAlert.OptionsAlert("Esta persona se eliminará, y ya no estará disponible para el resto de operaciones.", "Eliminar de todos modos").then((response) => {
			if (response) {
				DeletePerson(person.id);
			}
		});
	};

	const DeletePerson = async (person_id) => {
		var response = await apiPerson.deletePerson(person_id);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			GetPersons();
		}
	};

	useEffect(() => {
		GetPersons();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Lista de Personas Registradas:" />
				<BreadCrumb items={[{ label: "Personas" }, { label: "Lista de personas registradas", active: true }]} />
			</div>
			<div className="col-12">
				<TablePaginate
					buttons={buttons}
					headers={headers}
					rows={persons}
					values={values}
					onSearchApi={(page) => GetPersons(page)}
					onChangeFilter={setFilter}
					paginator={paginator}
				/>
			</div>
		</div>
	);
}

export default ListPersonsScreen;
