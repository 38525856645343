import React, { useEffect, useState } from "react";
import apiCompany from "../../../apis/company/apiCompany";
import AppAlert from "../../common/AppAlert";
import AppLoading from "../../common/AppLoading";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";
import apiService from "../../../apis/service/apiService";

function ServiceCreateScreen() {
	const [name, setName] = useState("");
	const [price, setPrice] = useState(null);
	const [endTime, setEndTime] = useState(null);
	const [initTime, setInitTime] = useState(null);

	const [company, setCompany] = useState({});
	const [loading, setLoading] = useState(false);
	const [companyList, setCompanyList] = useState([]);
	const [errorsForm, setErrorsForm] = useState({});

	const EditContractor = async () => {
		const service = {
			name: name,
			company_id: company.value,
			start_time: initTime,
			end_time: endTime,
			price: price,
		};

		setLoading(true);
		const response = await apiService.createService(service);
		setLoading(false);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			setErrorsForm({});
		} else {
			setErrorsForm(response);
		}
	};

	const GetCompanies = async () => {
		const response = await apiCompany.getCompaniesDropdown();

		if (response.ok) {
			setCompanyList(response.data);
		}
	};

	useEffect(() => {
		GetCompanies();
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Registro de Servicio" />
				<BreadCrumb items={[{ label: "Servicios" }, { label: "Registro de Servicio", active: true }]} />
			</div>
			<div className="card col-12">
				<div className="row card-body col-12">
					<div className="col-6">
						<AppInput
							label="Nombre del Servicio"
							type="text"
							onChange={({ target }) => setName(target.value)}
							placeholder="Nombre del Servicio"
							errors={errorsForm.name}
						/>
					</div>
					<div className="col-6">
						<AppInput
							label="Precio del Servicio"
							type="text"
							onChange={({ target }) => setPrice(target.value)}
							placeholder="Precio"
							errors={errorsForm.price}
						/>
					</div>
					<div className="col-3">
						<AppInput
							label="Hora Inicio"
							type="text"
							onChange={({ target }) => setInitTime(target.value)}
							placeholder="Inicio"
							errors={errorsForm.start_time}
						/>
					</div>
					<div className="col-3">
						<AppInput label="Hora Fin" type="text" onChange={({ target }) => setEndTime(target.value)} placeholder="Fin" errors={errorsForm.end_time} />
					</div>
					<div className="col-6">
						<AppSelect label="Compañía" items={companyList} onChange={(dinner) => setCompany(dinner ? dinner : {})} errors={errorsForm.company_id} />
					</div>
					<div className="col-12 text-center my-4">
						<button className="btn btn-primary" onClick={EditContractor}>
							Registrar Servicio
						</button>
					</div>
				</div>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default ServiceCreateScreen;
