import React from "react";
import AppSubTitle from "./AppSubTitle";

function AppListItem({ title, subtitle, iconName, onClick, btnText }) {
	return (
		<div className="">
			<AppSubTitle text={title} />
			<span className="small">{subtitle}</span>
			{onClick && (
				<button className="btn btn-sm" onClick={onClick}>
					{btnText}
				</button>
			)}
		</div>
	);
}

export default AppListItem;
