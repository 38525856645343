import React, { useEffect, useState } from "react";
import apiPerson from "../../../apis/person/apiPerson";
import AppSelect from "../../controls/AppSelect";
import AppClock from "../../controls/AppClock";
import AppTitle from "../../controls/AppTitle";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import apiProduct from "../../../apis/product/apiProduct";
import AppSubTitle from "../../controls/AppSubTitle";
import { useHistory, useLocation } from "react-router";

function SaleDetailScreen() {
	const history = useHistory();
	const location = useLocation();

	const [person, setPerson] = useState({});
	const [totalAmount, setTotalAmount] = useState(0);
	const [personList, setPersonList] = useState([]);
	const [productList, setProductList] = useState([]);
	const [productListSelected, setProductListSelected] = useState([]);
	const [personListToSearch, setPersonListToSearch] = useState([]);

	const [saledDate, setSaledDate] = useState(null);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		const sale = location.state;
		GetAllPersons(sale.person);
		setSaledDate(sale.saled_date);
		GetAllProducts(sale.products);
		return () => {};
	}, []);

	const GetAllProducts = async (details = []) => {
		var response = await apiProduct.getProductsDropdown();

		if (response.ok) {
			const productListSelected = [];
			details.forEach((detail) => {
				const productFound = response.data.find((x) => x.value == detail.product);
				if (productFound) {
					productListSelected.push({
						id: productFound.id,
						label: productFound.label,
						name: productFound.label,
						price: productFound.price,
						quantity: detail.quantity,
					});
				}
			});
			setProductList(response.data);
			setProductListSelected(productListSelected);
			CalculateAmountToPay(productListSelected);
		}
	};

	const GetAllPersons = async (person_id) => {
		var response = await apiPerson.getPersonsDropdown();
		if (response.ok) {
			const person = response.data.find((x) => x.value == person_id);
			setPerson(person);
			setPersonList(response.data);
		}
	};

	const CalculateAmountToPay = (productListSelected) => {
		var totalPrice = 0;

		for (let index = 0; index < productListSelected.length; index++) {
			totalPrice += productListSelected[index].price * productListSelected[index].quantity;
		}
		totalPrice = totalPrice.toFixed(2);
		setTotalAmount(totalPrice);
	};

	const onInputQuantityChange = (product_id, value) => {
		var list = productListSelected;
		const product = list.find((x) => x.id == product_id);

		if (product) {
			const productIndex = list.findIndex((x) => x.id == product_id);
			product.quantity = value;
			list.splice(productIndex, 1, product);
		}
		setProductListSelected(list);
		CalculateAmountToPay(list);
	};

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Detalle de Ventas:" />
				<AppBreadCrumb items={[{ label: "Ventas" }, { label: "Detalle de Venta:", active: true }]} />
			</div>
			<div className="col-12 card shadow">
				<div className="row card-body">
					<div className="col-12 form-group text-center">
						<label htmlFor="exampleInputtext1">
							<b>HORA Y FECHA DE VENTA</b>
						</label>
						<br />
						<span>{saledDate}</span>
					</div>
					<div className="col-6 form-group">
						<AppSelect isDisabled={true} value={person} errors={errors.person_id} label="Datos del cliente" items={personListToSearch} />
					</div>
					<div className="col-6 form-group">
						<AppSelect isDisabled={true} value={productListSelected} isMulti={true} label="Seleccione los Productos" items={productList} />
					</div>
					<div className="col-md-12">
						<AppSubTitle text="PRODUCTOS VENDIDOS: " />
						<div className="table-sm table-response-md">
							<table className="table w-100 table-sm small">
								<thead className="table-info">
									<tr>
										<th>Producto</th>
										<th>Precio</th>
										<th>Cantidad</th>
									</tr>
								</thead>

								<tbody>
									{productListSelected.map((product, index) => {
										return (
											<tr key={index}>
												<td>{product.name}</td>
												<td>s./ {product.price}</td>
												<td>
													<input
														type="number"
														readOnly
														className="form-control disabled"
														onChange={({ target }) => onInputQuantityChange(product.id, target.value)}
														defaultValue={product.quantity}
													/>
												</td>
											</tr>
										);
									})}
								</tbody>
								<tfoot>
									<tr>
										<td colSpan={3}>Monto Total: s/ {totalAmount}</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SaleDetailScreen;
