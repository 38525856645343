import React, { useEffect, useState } from "react";
import apiCompany from "../../../apis/company/apiCompany";
import AppAlert from "../../common/AppAlert";
import AppLoading from "../../common/AppLoading";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";
import { useHistory, useLocation } from "react-router";
import apiWorkUnit from "../../../apis/workUnit/apiWorkUnit";

function WorkUnitEditScreen() {
	const location = useLocation();
	const history = useHistory();

	const [id, setId] = useState(null);
	const [name, setName] = useState("");
	const [company, setCompany] = useState({});
	const [loading, setLoading] = useState(false);
	const [companyList, setCompanyList] = useState([]);
	const [errorsForm, setErrorsForm] = useState({});

	const EditWorkUnit = async () => {
		const workUnit = {
			id: id,
			name: name,
			company_id: company.value,
		};

		setLoading(true);
		const response = await apiWorkUnit.updateWorkUnit(workUnit);
		setLoading(false);

		if (response.ok) {
			history.goBack();
			setErrorsForm({});
			AppAlert.SimpleAlert(response.message);
		} else {
			setErrorsForm(response);
		}
	};

	const GetCompanies = async (company_id) => {
		const response = await apiCompany.getCompaniesDropdown();

		if (response.ok) {
			const company = response.data.find((x) => x.value == company_id);
			setCompany(company);
			setCompanyList(response.data);
		}
	};

	useEffect(() => {
		const workUnit = location.state;

		setId(workUnit.id);
		setName(workUnit.name);
		GetCompanies(workUnit.company);
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Edición de Unidad de Trabajo" />
				<BreadCrumb items={[{ label: "Unidades de Trabajo" }, { label: "Edición de Unidad de Trabajo", active: true }]} />
			</div>
			<div className="card col-12">
				<div className="row card-body col-12">
					<div className="col-6">
						<AppInput
							label="Nombre del Unidad de Trabajo"
							type="text"
							defaultValue={name}
							onChange={({ target }) => setName(target.value)}
							placeholder="Nombre del Unidad de Trabajo"
							errors={errorsForm.name}
						/>
					</div>
					<div className="col-6">
						<AppSelect
							value={company}
							label="Compañía"
							items={companyList}
							onChange={(dinner) => setCompany(dinner ? dinner : {})}
							errors={errorsForm.company_id}
						/>
					</div>
					<div className="col-12 text-center my-4">
						<button className="btn btn-primary" onClick={EditWorkUnit}>
							Editar Unidad de Trabajo
						</button>
					</div>
				</div>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default WorkUnitEditScreen;
