import React, { useRef, useState } from "react";
import apiPerson from "../../../apis/person/apiPerson";
import AppInput from "../../controls/AppInput";
import InstallationActToPrint from "./InstallationActToPrint";
import { useReactToPrint } from "react-to-print";
import AppTitle from "../../controls/AppTitle";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import AppSubTitle from "../../controls/AppSubTitle";
import appColors from "../../../config/appColors";
import AppCalendar from "../../controls/AppCalendar";

function MeterInstallationCertificate() {
	const componentRef = useRef();
	const [errors, setErrors] = useState({});
	const [inscriptionCode, setInscriptionCode] = useState("");
	const [person, setPerson] = useState({});
	const [date, setDate] = useState(new Date());

	const SearchPerson = async () => {
		var response = await apiPerson.getPersonByInscriptionCode(inscriptionCode);

		if (response.ok) {
			setPerson(response.data);
			SendToPrintForm();
		} else {
			setPerson({});
		}
	};

	const onChangeInscriptionCode = (event) => {
		if (event.key === "Enter") {
			SearchPerson();
		}
	};

	const SendToPrintForm = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<div className="row p-5">
			<div className="col-10">
				<AppTitle text="Acta de Instalacion de medidor: " />
				<AppBreadCrumb items={[{ label: "Formularios" }, { label: "actas de Retiro de medidor", active: true }]} />
			</div>
			<div className="col-md-12 card">
				<div className="card-body text-center row">
					<div className="col-md-12 py-5">
						<AppCalendar label="Fecha de Asignacion:" value={date} onChange={setDate} />
					</div>
					<div className="col-md-12">
						<AppInput
							label="Codigo de Inscripcion del Usuario"
							onKeyPress={(event) => onChangeInscriptionCode(event)}
							errors={errors.id}
							onChange={({ target }) => setInscriptionCode(target.value)}
							placeholder="Codigo de Inscripcion"
						/>
					</div>
					<div className="col-md-12">
						{!person.id ? (
							<AppSubTitle color={appColors.red_05} text={"Usuario Requerido"} />
						) : (
							<AppSubTitle color={appColors.green_05} text="Usuario encontrado" />
						)}
					</div>
				</div>
			</div>

			<div className="col-md-12">
				<InstallationActToPrint ref={componentRef} person={person} date={date.toLocaleDateString()} />
			</div>
		</div>
	);
}

export default MeterInstallationCertificate;
