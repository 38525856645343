import React, { useEffect, useState } from "react";
import apiPerson from "../../../apis/person/apiPerson";
import BreadCrumb from "../../common/AppBreadCrumb";
import TablePaginate from "../../components/TablePaginate";
import AppTitle from "../../controls/AppTitle";

function ListPersonsNotifications() {
	const headers = ["Fecha", "Inscripcion", "Nombres", "Sector", "Fecha de Notificacion"];
	const values = ["created", "inscription_code", "person", "contractor", "notification_date"];

	const [filter, setFilter] = useState("");
	const [paginator, setPaginator] = useState({});
	const [persons, setPersons] = useState([]);

	const GetPersons = async (page = 1) => {
		var response = await apiPerson.getPersonsPaginatedByNotificationDate(page, filter);
		if (response.ok) {
			setPaginator(response.data.paginator);
			setPersons(response.data.results);
		}
	};

	useEffect(() => {
		GetPersons();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Personas Notificadas:" />
				<BreadCrumb items={[{ label: "Personas" }, { label: "Lista de personas notificadas", active: true }]} />
			</div>
			<div className="col-12">
				<TablePaginate
					headers={headers}
					rows={persons}
					placeholder="Fecha de Notificacion (yyyy-mm-dd) ... "
					values={values}
					onSearchApi={(page) => GetPersons(page)}
					onChangeFilter={setFilter}
					paginator={paginator}
				/>
			</div>
		</div>
	);
}

export default ListPersonsNotifications;
