import React from "react";
import BreadCrumb from "../../common/AppBreadCrumb";
import AttendanceExtempCreateForm from "../../components/AttendanceExtempCreateForm";
import AppTitle from "../../controls/AppTitle";

function AttendanceExtempScreen() {
	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Registro de Asistencias Extemporáneas:" />
				<BreadCrumb items={[{ label: "Asistencias" }, { label: "Registro de Asistencias Extemporáneas", active: true }]} />
			</div>
			<div className="col-12">
				<AttendanceExtempCreateForm />
			</div>
		</div>
	);
}

export default AttendanceExtempScreen;
