import apiBase from "../apiBase";

export default {
	// DINNER
	createDinner(dinner) {
		const url = "api/dinner/create";
		return apiBase.post(url, dinner);
	},
	updateDinner(dinner) {
		const url = "api/dinner/update";
		return apiBase.put(url, dinner);
	},
	deleteDinner(dinner_id) {
		const url = "api/dinner/delete/" + dinner_id;
		return apiBase.delete(url);
	},
	getDinners(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/dinner/getDinners?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},
	getDinnersPaginated(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/dinner/getDinnersPaginated?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},
	getDinnersDropdown(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/dinner/getDinnersDropdown?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},

	// DINNER POINTS
	createDinnerPoint(dinnerPoint) {
		const url = "api/dinner/dinnerPoint/create";
		return apiBase.post(url, dinnerPoint);
	},
	updateDinnerPoint(dinnerPoint) {
		const url = "api/dinner/dinnerPoint/update";
		return apiBase.put(url, dinnerPoint);
	},
	deleteDinnerPoint(dinner_point_id) {
		const url = "api/dinner/dinnerPoint/delete/" + dinner_point_id;
		return apiBase.delete(url);
	},
	getDinnerPoints(page = 1) {
		const url = "api/dinner/dinnerPoint/getDinnerPoints";
		return apiBase.get(url);
	},
	getDinnerPointsDropdown(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/dinner/dinnerPoint/getDinnerPointsDropdown?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},
	getDinnerPointsPaginated(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/dinner/dinnerPoint/getDinnerPointsPaginated?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},
};
