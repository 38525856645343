import apiSale from "../../../apis/sale/apiSale";
import React, { useEffect, useState } from "react";
import AppAlert from "../../common/AppAlert";
import apiPerson from "../../../apis/person/apiPerson";
import AppSelect from "../../controls/AppSelect";
import helperDate from "../../../helpers/helperDate";
import AppClock from "../../controls/AppClock";
import AppLoading from "../../common/AppLoading";
import AppTitle from "../../controls/AppTitle";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import apiProduct from "../../../apis/product/apiProduct";
import AppSubTitle from "../../controls/AppSubTitle";
import helperSession from "../../../helpers/helperSession";

function SaleCreateScreen() {
	const [person, setPerson] = useState({});
	const [totalAmount, setTotalAmount] = useState(0);
	const [personList, setPersonList] = useState([]);
	const [productList, setProductList] = useState([]);
	const [productListSelected, setProductListSelected] = useState([]);
	const [personListToSearch, setPersonListToSearch] = useState([]);

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		GetAllPersons();
		GetAllProducts();
		return () => {};
	}, []);

	const GetAllProducts = async () => {
		var response = await apiProduct.getProductsDropdown();

		if (response.ok) {
			setProductList(response.data);
		}
	};

	const GetAllPersons = async () => {
		var response = await apiPerson.getPersonsDropdown();
		if (response.ok) {
			setPersonList(response.data);
		}
	};

	const CreateSale = async () => {
		const sale = {
			saled_date: helperDate.getCurrentToServer(),
			user_id: helperSession.getCurrentUser().id,
			products: productListSelected,
			person_id: person ? person.value : null,
		};

		setLoading(true);
		const response = await apiSale.createSale(sale);
		setLoading(false);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
		} else {
			setErrors(response);
		}
	};

	const onInputChange = (value = "") => {
		if (value && value.length > 2) {
			var persons = personList;
			const filteredPersons = persons.filter((x) => x.label.toUpperCase().includes(value.toUpperCase()));
			setPersonListToSearch(filteredPersons);
		} else {
			setPersonListToSearch([]);
		}
	};

	const onSetProductListSelected = (products = []) => {
		var productsList = products.map((product) => ({ name: product.label, id: product.value, price: product.price, quantity: 1 }));

		setProductListSelected(productsList);
		CalculateAmountToPay(productsList);
	};

	const CalculateAmountToPay = (productListSelected) => {
		var totalPrice = 0;

		for (let index = 0; index < productListSelected.length; index++) {
			totalPrice += productListSelected[index].price * productListSelected[index].quantity;
		}
		totalPrice = totalPrice.toFixed(2);
		setTotalAmount(totalPrice);
	};

	const onInputQuantityChange = (product_id, value) => {
		var list = productListSelected;
		const product = list.find((x) => x.id == product_id);

		if (product) {
			const productIndex = list.findIndex((x) => x.id == product_id);
			product.quantity = value;
			list.splice(productIndex, 1, product);
		}
		setProductListSelected(list);
		CalculateAmountToPay(list);
	};

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Registro de Ventas:" />
				<AppBreadCrumb items={[{ label: "Ventas" }, { label: "Registro de Venta:", active: true }]} />
			</div>
			<div className="col-12 card shadow">
				<div className="row card-body">
					<div className="col-12 form-group text-center">
						<label htmlFor="exampleInputtext1">
							<b>HORA Y FECHA DE VENTA</b>
						</label>
						<AppClock />
					</div>
					<div className="col-6 form-group">
						<AppSelect errors={errors.person_id} label="Datos del cliente" onInputChange={onInputChange} onChange={setPerson} items={personListToSearch} />
					</div>
					<div className="col-6 form-group">
						<AppSelect isMulti={true} label="Seleccione los Productos" onChange={onSetProductListSelected} items={productList} />
					</div>
					<div className="col-md-6">
						<AppSubTitle text="PRODUCTOS SELECCIONADOS: " />
						<AppSubTitle text="Puede modificar la cantidad de los productos: " />
						<div className="table-sm table-response-md">
							<table className="table w-100 table-sm small">
								<thead className="table-info">
									<tr>
										<th>Producto</th>
										<th>Precio</th>
										<th>Cantidad</th>
									</tr>
								</thead>

								<tbody>
									{productListSelected.map((product, index) => {
										return (
											<tr key={index}>
												<td>{product.name}</td>
												<td>s./ {product.price}</td>
												<td>
													<input
														type="number"
														className="form-control-sm"
														onChange={({ target }) => onInputQuantityChange(product.id, target.value)}
														defaultValue={product.quantity}
													/>
												</td>
											</tr>
										);
									})}
								</tbody>
								<tfoot>
									<tr>
										<td colSpan={3}>Monto Total: {totalAmount}</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
					<div className="col-md-6 text-center my-4 m-auto">
						<button className="btn btn-primary btn-sm" onClick={CreateSale}>
							Registrar Venta
						</button>
					</div>
				</div>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default SaleCreateScreen;
